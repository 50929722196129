import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import ProjectStyle1 from "../components/Projects/ProjectStyle1"

const Portfolio = () => (
  <Layout>
    <Seo title="Portfolio" />

    <Navbar />

    {/*<PageBanner pageTitle="Portfolio" />*/}

    <ProjectStyle1 />

    <Footer />
  </Layout>
)

export default Portfolio
