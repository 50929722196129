import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
// Shape Images
import Shape2 from "../../assets/images/shape2.svg"
import Shape4 from "../../assets/images/shape4.svg"

const query = graphql`
  {
    allStrapiProjects {
      nodes {
        id
        title
        image {
          childImageSharp {
             fluid(jpegProgressive:true, maxWidth:650, webpQuality: 80, jpegQuality: 80) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        shortText
        longDescription
        slug
      }
    }
  }
`

const ProjectStyle1 = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiProjects: { nodes: projects },
  } = data

  return (
    <div className="works-area pt-120-b-80">
      <div className="container">
        <div className="section-title">
          {/*<span className="sub-title">Our Solutions</span>*/}
          <h1>Portfolio</h1>
          <div className="bar"/>
        </div>
        <div className="row portfolio-section justify-content-center" style={{ rowGap: '30px' }}>
          {projects.map(project => (
            <div className="col-lg-4 col-md-6" key={project.id}>
                <Link to={`/portfolio/${project.slug}/`}>
                <div className="single-works">
                <img
                  src={project.image.childImageSharp.fluid.srcWebp}
                  alt={project.title}
                />

                {/*<Link to={`/projects/${project.slug}`} className="icon">*/}
                {/*  <Icon.Settings />*/}
                {/*</Link>*/}

                <div className="works-content">
                  <h3>
                    {/*<Link to={`/portfolio/${project.slug}`}>*/}
                      {project.title}
                    {/*</Link>*/}
                  </h3>
                  <p>{project.shortText}</p>
                </div>
              </div>
                </Link>
            </div>
          ))}
        </div>
      </div>

      {/* Shape Images */}
      <div className="shape8 rotateme">
        <img src={Shape2} width={22} height={22} alt="shape" />
      </div>
      <div className="shape2 rotateme">
        <img src={Shape2} width={22} height={22} alt="shape" />
      </div>
      <div className="shape4">
        <img src={Shape4} width={22} height={22} alt="shape" />
      </div>
    </div>
  )
}

export default ProjectStyle1
